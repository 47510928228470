/*
=============== 
About
===============
*/
/* section added to globals */
/* title added to globals */
/* section center added to globals */
.about-img,
.about-info {
    margin-bottom: 2rem;
}

.about-center {
    h3 {
        margin-bottom: 2.188rem;
    }
}

.about-skills {
    &-title {
        // font-size: 2.4rem;
        // font-weight: 700;
        // margin-bottom: 3rem;
    }

    &-content {
        display: flex;
        flex-wrap: wrap;

        &-item {
            padding: 0.5rem 1rem;
            margin-bottom: 1.5rem;
            margin-right: 1.5rem;
            font-size: 1rem;
            background: rgba(153, 153, 153, 0.2);
            border-radius: 5px;
            font-weight: 600;
            color: #666;
            transition: transform 1s;

            &:hover {
                transform: scale(1.2);
                cursor: pointer;
                color: var(--clr-black);
                background-color: var(--clr-yellow);
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .about-center {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
    }
    .about-img,
    .about-info {
        margin-bottom: 0;
    }
}
@media screen and (min-width: 1170px) {
    .about-img::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 0.5rem solid var(--clr-primary-5);
        box-sizing: border-box;
        top: -1.5rem;
        left: -1.5rem;
    }

    .about-img {
        position: relative;
    }
    .about-photo {
        position: relative;
    }
}
