/*
=============== 
Footer
===============
*/
.footer {
    background: var(--clr-black);
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
}
.footer-links,
.footer-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}
.footer-link {
    color: var(--clr-white);
    text-transform: capitalize;
    font-size: 1.25rem;
    margin-right: 1rem;
    letter-spacing: var(--spacing);
    transition: var(--transition);
}
.footer-link:hover {
    color: var(--clr-yellow-2);
}
.footer-icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--clr-white);
    transition: var(--transition);
}
.footer-icon:hover {
    color: var(--clr-yellow-2);
}
.copyright {
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    color: var(--clr-white);
    font-size: 1.125rem !important;
}
.copyright span {
    margin-left: 0.5rem;
}
