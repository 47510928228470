/*
=============== 
Hero
===============
*/

.hero {
    background: /*linear-gradient($softBlue, $softBlack),*/ url("../../images/hero.webp")
        center/cover no-repeat;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-banner {
    text-align: center;
    color: var(--clr-black);
    padding: 0 3rem;
}
.hero-banner h1 {
    text-transform: uppercase;
}
.hero-banner p {
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
    color: var(--clr-black);
    letter-spacing: var(--spacing);
}
.hero-btn {
    padding: 0.9rem 1.6rem;
    font-size: 1.25rem;
    background: var(--clr-yellow);
    color: var(--clr-black);
}
.hero-btn:hover {
    background: transparent;
    color: var(--clr-yellow);
    border-color: var(--clr-yellow);
}
@media screen and (min-width: 768px) {
    .hero-banner {
        padding: 0;
    }
    .hero-banner p {
        max-width: 54%;
    }
}
