/*
=============== 
Featured Tours
===============
*/

.tour-card {
    transition: var(--transition);
    background: var(--clr-grey-10);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}
.tour-card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}
.tour-img-container {
    position: relative;
}
.tour-img {
    height: 15rem;
    object-fit: cover;
}
.tour-date {
    position: absolute;
    right: 0;
    bottom: 0;
    background: var(--clr-primary-8);
    color: var(--clr-primary-1);
    text-transform: capitalize;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
}
.tour-info {
    padding: 1.25rem 1.5rem;
}
/* additional css for different layout*/
.tour-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.tour-title p,
.tour-title h4 {
    margin-bottom: 0.75rem;
    line-height: 1.25;
}
.tour-title p {
    color: var(--clr-grey-7);
}
/* end of additional css for different layout*/
.tour-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.tour-footer p {
    line-height: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    color: var(--clr-primary-5);
}
.tour-footer p span {
    margin-right: 0.25rem;
}
.tour-btn {
    text-align: center;
}
@media screen and (min-width: 768px) {
    .featured-center {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}
@media screen and (min-width: 1170px) {
    .featured-center {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
