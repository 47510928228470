/*
=============== 
Projects
===============
*/
.projects {
    background: var(--clr-grey-10);

    &-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        margin-bottom: 8rem;

        &:last-child {
            margin-bottom: 0;
        }

        // &:nth-child(2) {
        //     display: none;
        // }

        @include media(">mini-tablet") {
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            grid-gap: 5rem;
            margin-bottom: 11rem;
        }

        &-box-img {
            overflow: hidden;

            &-item {
                width: 100%;
                display: block;
                -o-object-fit: cover;
                object-fit: cover;

                @include media(">mini-tablet") {
                    width: 80% !important;
                    height: auto;
                }
            }
        }

        &-content {
            padding: 2rem 0;
            display: flex-start;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            @include media(">mini-tablet") {
                align-items: flex-start;
                padding: 0;
            }

            &-title {
                font-weight: 500;
                font-size: 1.875rem;
                margin-bottom: 1.563rem;
                text-transform: uppercase;
            }

            &-text {
                font-size: 1.375rem;
                color: var(--clr-black);
                max-width: 60rem;
                line-height: 1.4;
                margin-bottom: 2.188rem;
            }

            &-skills {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 1.875rem;

                &-item {
                    padding: 0.5rem 1rem;
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                    font-size: 0.8rem;
                    background: rgba(153, 153, 153, 0.2);
                    border-radius: 5px;
                    font-weight: 600;
                    color: #666;
                    transition: transform 1s;

                    &:hover {
                        transform: scale(1.2);
                        cursor: pointer;
                        color: var(--clr-black);
                        background-color: var(--clr-yellow);
                    }
                }
            }
        }
    }
}
