/*
=============== 
Navbar
===============
*/
/* add :not(.nav-logo) to img */

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--clr-white);
    box-shadow: var(--dark-shadow);
    z-index: 2;
    height: 5rem;
    display: flex;
    align-items: center;
}

.nav-icons {
    display: none;
}

.nav-center {
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    width: 90vw;
}

.nav-header {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .nav-logo {
        width: 4.688rem;
        height: auto;

        @include media(">mini-tablet") {
            width: 3.25rem;
        }
    }
}

.nav-toggle {
    background: transparent;
    border: none;
    outline: none;
    font-size: 1.5rem;
    color: var(--clr-yellow);
    cursor: pointer;
    transition: var(--transition);
}
.nav-toggle:hover {
    transform: scale(1.2);
}
.nav-link {
    font-size: 1.25rem;
    font-family: "Kanit", sans-serif;
    display: block;
    padding: 1rem 2rem;
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    color: var(--clr-black);
    cursor: pointer;
}
.nav-link:hover {
    color: var(--clr-primary-1);
    background: var(--clr-primary-8);
    padding-left: 2.25rem;
}
/* nav toggle functionality */
.nav-links {
    height: 0;
    overflow: hidden;
    // transition: var(--transition);

    li {
        cursor: pointer;
    }
}

.nav-mobile {
    display: block;
    height: 100vh;
    display: block;
    background-color: var(--clr-yellow);
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
}
.show-links {
    height: 280px;
}

.nav-icons {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 25rem;
    bottom: 0;
    z-index: 3;
    padding: 20px;
    background-color: var(--clr-white);
    height: 7.188rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.nav-icon {
    color: var(--clr-yellow);
    font-size: 1.2rem;
    transition: transform 1s;

    .fab {
        font-size: 35px;
        transition: transform 1s;
    }

    .fa {
        font-size: 35px;
        transition: transform 1s;
    }
}
.nav-icon:hover {
    color: var(--clr-yellow-2);
    cursor: pointer;

    .fab {
        transform: scale(1.2);
        cursor: pointer;
    }

    .fa {
        transform: scale(1.2);
        cursor: pointer;
    }
}
/* nav at bigger screen size */
@media screen and (min-width: 992px) {
    /* hide nav toggle button */
    .nav-toggle {
        display: none;
    }
    /* show links */
    .nav-links {
        height: auto;
        display: flex;
    }
    .nav-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* optional */
        flex-wrap: wrap;
    }
    .nav-header {
        padding: 0 0;
    }

    .nav-link:hover {
        padding: 1rem 2rem;
        color: var(--clr-yellow-2);
        background: transparent;
        cursor: pointer;
    }
}
